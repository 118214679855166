<template>
  <div class="inviteBox">
    <NavBar title="邀请好友"></NavBar>
    <div class="bannerBox">
      <div class="title">专属邀请码</div>
      <div class="code">
        <span>{{ code }}</span>
        <img
          src="@/assets/images/personal/invite/a2.png"
          alt=""
          v-clipboard:copy="code"
          v-clipboard:success="() => $toast('复制成功')"
          v-clipboard:error="() => $toast('复制失败')"
        />
      </div>
    </div>
    <div class="contentBox">
      <div class="topBox">
        <div>
          <div class="number">{{ totalInviteNumber }}</div>
          <div class="intro">已邀请人数</div>
          <van-button block type="danger" plain class="reset" color="#FF6322" @click="shareShow = true">
            分享邀请海报
          </van-button>
        </div>
        <div>
          <div class="number">{{ buyNumber }}</div>
          <div class="intro">购买算力人数</div>
          <van-button
            block
            type="danger"
            plain
            class="reset"
            color="#FF6322"
            v-clipboard:copy="inviteLink"
            v-clipboard:success="copyLink"
            v-clipboard:error="() => $toast('复制失败')"
          >
            复制分享链接
          </van-button>
        </div>
      </div>
      <Space :height="30"></Space>
      <div class="listBox">
        <div class="title">推广记录</div>
        <div class="header">
          <span>时间</span>
          <span>手机号</span>
          <span>状态</span>
        </div>
        <ListView :get-list="getList" empty="暂无记录" pull-refresh>
          <template v-slot="{ data }">
            <div v-for="(item, index) of data" :key="index" class="list">
              <span>{{ item.time }}</span>
              <span>{{ $formatPhone(item.phone) }}</span>
              <span>{{ item.status }}</span>
            </div>
          </template>
        </ListView>
      </div>
      <Space :height="46"></Space>
    </div>
    <van-image
      :src="require('@/assets/images/personal/invite/share.png')"
      fit="cover"
      ref="vanImage"
      style="display: none;"
    ></van-image>
    <div class="qrcode" ref="qrcode" style="display: none;"></div>
    <van-popup v-model="shareShow">
      <div class="imgBox" ref="canvasBox">
        <div ref="cvp" style="position: absolute; left: 0; top: 0;"></div>
      </div>
      <Space :height="60"></Space>
      <div class="buttonBox">
        <van-button type="primary" block @click="saveCanvas">{{
          $isWeiXin() ? '长按图片保存本地' : '保存图片'
        }}</van-button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import QRCode from 'qrcodejs2';
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: {},
  data() {
    return {
      shareShow: false,
      code: '',
      buyNumber: '',
      totalInviteNumber: '',
      qrcodeObj: '',
    };
  },
  created() {
    this.getDetail();
  },
  // mounted() {},
  methods: {
    getDetail() {
      this.$http('post', '/v1/user/userInvitation').then(res => {
        this.code = res.data.inviteCode;
        this.buyNumber = res.data.buyNum;
        this.totalInviteNumber = res.data.totalInviteNum;
      });
    },
    async getList(page) {
      let res = await this.$http('get', '/v1/user/InvitationRecord', {
        page,
        size: 10,
      });

      let statusProcess = res => {
        switch (res) {
          case 1:
            return '已注册';
          case 2:
            return '已购买';
        }
      };

      res.data.list = res.data.list.map(item => {
        return {
          time: item.data,
          phone: item.phone,
          status: statusProcess(item.status),
        };
      });

      return res;
    },
    canvasImg() {
      if (this.$refs.cvp.innerHTML) return;

      let canvas = document.createElement('canvas');
      let cv = canvas.getContext('2d');
      canvas.width = 564;
      canvas.height = 1002;

      let vanimage = this.$refs.vanImage.$el;
      cv.drawImage(vanimage.childNodes[0], 0, 0);

      let codeCanvas = this.$refs.qrcode.childNodes[0];
      cv.drawImage(codeCanvas, 415, 866, 130, 130);

      let img = document.createElement('img');
      img.src = canvas.toDataURL('image/png');
      this.$refs.cvp.appendChild(img);
    },
    saveCanvas: _.debounce(function() {
      let cvpImage = this.$refs.cvp.childNodes[0];
      let imgUrl = cvpImage.getAttribute('src');
      if (this.$judgePhone() === 'A' && window.ipfshyys) {
        this.$http('post', '/v1/cdn/uploadImgBase64', {
          base64: imgUrl,
        }).then(res => {
          ipfshyys.saveToAlbum(res.data);
        });
        return;
      }
      let saveLink = document.createElement('a');
      saveLink.href = imgUrl;
      saveLink.download = 'share.png';
      saveLink.click();
    }),
    qrcode() {
      this.$nextTick(() => {
        this.$refs.qrcode.innerHTML = '';
        new QRCode(this.$refs.qrcode, {
          width: parseInt(this.$pxToPxRatio(260), 10),
          height: parseInt(this.$pxToPxRatio(260), 10),
          text: this.inviteLink,
          correctLevel: QRCode.CorrectLevel.H,
        });
      });
    },
    copyLink() {
      this.$toast('复制成功！快去分享给好友');
    },
  },
  watch: {
    inviteLink(res) {
      if (res) {
        this.qrcode();
      }
    },
    shareShow(res) {
      if (res) {
        this.$nextTick(() => {
          this.canvasImg();
        });
      }
    },
  },
  computed: {
    inviteLink() {
      return `${location.href}/ir?c=${this.code}`;
    },
  },
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.inviteBox {
  min-height: 100vh;
  background: linear-gradient(180deg, #ffffff 0%, #f3f3f8 100%);

  .bannerBox {
    background: url('~@/assets/images/personal/invite/a1.png') no-repeat center;
    background-size: cover;
    height: 664px;
    color: @white;
    padding-top: 226px;
    .title {
      font-size: 24px;
      text-align: center;
    }
    .code {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 11px;
      span {
        font-size: 36px;
        font-weight: bold;
      }
      img {
        width: 28px;
        height: 31px;
        margin-left: 8px;
      }
    }
  }

  .topBox {
    height: 325px;
    background: @white;
    box-shadow: 0px 2px 21px 2px rgba(239, 212, 212, 0.39);
    border-radius: 18px;
    margin-top: -152px;
    display: flex;
    padding: 0 64px;
    > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 54px;
      &:last-child {
        margin-left: 70px;
      }
      .number {
        font-size: 46px;
        color: @text-color2;
        font-weight: bold;
      }
      .intro {
        font-size: 24px;
        color: @intro-color;
        margin-top: 10px;
        margin-bottom: 53px;
      }
    }
  }

  .listBox {
    min-height: 300px;
    background: @white;
    box-shadow: 0px 2px 21px 2px rgba(239, 212, 212, 0.39);
    border-radius: 18px;
    padding: 30px;

    .title {
      font-size: 30px;
      color: black;
    }
    .header {
      display: flex;
      justify-content: space-between;
      span {
        font-size: 26px;
        color: @intro-color;
        margin-top: 20px;
        margin-bottom: 30px;
      }
    }
    .list {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      color: @text-color1;
    }
  }

  /deep/ .emptyTopSpace {
    display: none;
  }
}

.van-popup {
  width: 564px;
  .imgBox {
    height: 1002px;
    position: relative;
    .qrcode {
      position: absolute;
      right: 20px;
      bottom: 6px;
    }
  }
  .buttonBox {
    padding: 0 30px;
  }
}
</style>
